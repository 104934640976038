import { forwardRef, MouseEventHandler } from "react";

import { Text, TextProps, createStyles } from "@mantine/core";

const useStypes = createStyles(theme => ({
  root: {
    paddingBlock: 3,
    paddingInline: 7,
    borderRadius: theme.radius.xl,
    whiteSpace: "nowrap",
    display: "inline-block",
    textAlign: "center",
    lineHeight: 1.3,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "transparent",
  },
  grey: {
    backgroundColor: theme.colors.grey[2],
    borderColor: theme.colors.grey[2],
    color: theme.colors.grey[9],
  },
  lightgrey: {
    backgroundColor: theme.colors.grey[2],
    borderColor: theme.colors.grey[2],
    color: theme.colors.grey[5],
  },
  "bordered-grey": {
    backgroundColor: theme.white,
    borderColor: theme.colors.grey[5],
    color: theme.black,
  },
  green: {
    color: theme.colors.teal[7],
    backgroundColor: theme.colors.teal[1],
  },
  "bordered-green": {
    backgroundColor: theme.white,
    borderColor: theme.colors.teal[5],
    color: theme.colors.teal[5],
  },
  yellow: {
    color: theme.colors.yellow[9],
    backgroundColor: theme.colors.yellow[3],
  },
  red: {
    color: theme.colors.red[8],
    backgroundColor: theme.colors.red[2],
  },
  indigo: {
    backgroundColor: theme.colors.indigo[5],
    color: theme.white,
  },
  disabled: {
    backgroundColor: theme.colors.grey[1],
    color: theme.colors.grey[3],
  },
  interactive: {
    cursor: "pointer",
  },
  //TODO: Think of a better way to add new variants for future usage.
  beta: {
    color: theme.white,
    backgroundColor: theme.colors.indigo[5],
    borderRadius: theme.radius.sm,
    pointerEvents: "none",
    textTransform: "uppercase",
  },
}));

export type BasicChipVariants =
  | "grey"
  | "lightgrey"
  | "bordered-green"
  | "bordered-grey"
  | "indigo"
  | "beta"
  | "green"
  | "yellow"
  | "red"
  | "disabled";

export type BasicChipProps = TextProps & {
  chipVariant?: BasicChipVariants;
  onClick?: MouseEventHandler;
};

const BasicChip = forwardRef<HTMLDivElement, BasicChipProps>(({ chipVariant = "grey", ...textProps }, ref) => {
  const { classes, cx } = useStypes();

  return (
    <Text
      ref={ref}
      span
      weight={600}
      {...textProps}
      className={cx(classes.root, classes[chipVariant], textProps.onClick && classes.interactive)}
    />
  );
});

export default BasicChip;
