import { FC, PropsWithChildren } from "react";

import { ModalsProvider as MantineModalsProvider } from "@mantine/modals";

import { LogoutModal, SubmitEntitySuccess, BookCallModal } from "@Components/modals";

const ModalsProvider: FC<PropsWithChildren> = ({ children }) => (
  <MantineModalsProvider
    modals={{
      logout: LogoutModal,
      submitEntity: SubmitEntitySuccess,
      bookCall: BookCallModal,
    }}
    modalProps={{
      centered: true,
      padding: "md",
    }}
  >
    {children}
  </MantineModalsProvider>
);

export default ModalsProvider;
