import { forwardRef, ReactNode } from "react";

import { Text, Box, Group, BoxProps, createStyles } from "@mantine/core";

import BadgePreview from "@Components/BadgePreview";

const useStyles = createStyles((theme, contentBordered: boolean) => ({
  root: {
    border: theme.other.getDefaultBorder(theme),
    backgroundColor: theme.white,
    position: "relative",
  },
  titleRow: {
    backgroundColor: theme.white,
    paddingInline: theme.spacing.sm,
    paddingBlock: 10,
    borderBottom: theme.other.getDefaultBorder(theme),
  },
  title: {
    fontWeight: theme.other.fontWeight.bold,
  },
  buttons: {
    gap: theme.spacing.xs,
    [theme.fn.smallerThan("xs")]: {
      alignItems: "end",
      flexDirection: "column",
    },
  },
  footerLink: {
    display: "flex",
    justifyContent: "flex-end",
    paddingInline: theme.spacing.sm,
    paddingBlock: theme.spacing.xs,
    borderTop: theme.other.getDefaultBorder(theme),
  },
  // TODO: Not very clever - remove/update once there's time
  content: {
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    margin: contentBordered ? -1 : undefined,
  },
}));

type PageSectionProps = BoxProps & {
  title: ReactNode;
  buttons?: ReactNode;
  footerLink?: ReactNode;
  contentBordered?: boolean;
  infoButton?: ReactNode;
  isPreview?: boolean;
  onboarding?: ReactNode;
};

const PageSection = forwardRef<HTMLDivElement, PageSectionProps>(
  (
    {
      title,
      buttons,
      footerLink,
      contentBordered = false,
      infoButton,
      isPreview,
      className,
      children,
      onboarding,
      ...boxProps
    },
    ref
  ) => {
    const { classes, cx } = useStyles(contentBordered);

    return (
      <Box ref={ref} className={cx(classes.root, className)} {...boxProps}>
        <Group className={classes.titleRow} position="apart" noWrap>
          <Group spacing="xs">
            <Text className={classes.title}>{title}</Text>
            {infoButton}
            {isPreview && <BadgePreview />}
            {onboarding}
          </Group>
          <Group className={classes.buttons}>{buttons}</Group>
        </Group>
        <Box className={classes.content}>{children}</Box>
        {footerLink && <Box className={classes.footerLink}>{footerLink}</Box>}
      </Box>
    );
  }
);

PageSection.displayName = "PageSection";

export default PageSection;
