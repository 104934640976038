import dayjs, { type ManipulateType, type ConfigType } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(isBetween);
dayjs.extend(relativeTime);

export const getOldestTimestamp = () => dayjs(0o0).valueOf();

/**
 * Converts the provided date into a JavaScript Date object using the dayjs library.
 *
 * @param {ConfigType} date - The date to be converted. This parameter is optional. If not provided, the function will use the current date and time.
 *
 * @returns {Date} - Returns a JavaScript Date object representing the provided date or the current date and time if no date is provided.
 *
 * @example
 * // Returns a Date object representing the current date and time
 * const currentDate = getDate();
 *
 * @example
 * // Returns a Date object representing January 1, 2022
 * const specificDate = getDate('2022-01-01');
 */
export const getDate = (date?: ConfigType): Date => dayjs(date).toDate();

export const getFormattedDate = (date: ConfigType, format = "DD MMM YYYY"): Nullable<string> => {
  if (!date) {
    return null;
  }

  const parsedDate = dayjs(date);

  return parsedDate.isValid() ? parsedDate.format(format) : null;
};

export const getFormattedProjectDate = (
  date: ConfigType,
  format = "DD MMM YYYY",
  passed: boolean
): Nullable<string> => {
  if (!date) {
    return null;
  }

  const parsedDate = dayjs(date);

  if (!parsedDate.isValid()) {
    return null;
  }

  if (!passed) {
    const isFirstDayOfYear = parsedDate.isSame(parsedDate.startOf("year"), "day");
    const isLastDayOfYear = parsedDate.isSame(parsedDate.endOf("year"), "day");

    if (isFirstDayOfYear || isLastDayOfYear) {
      return parsedDate.format("YYYY");
    }

    return parsedDate.format("MMM YYYY");
  }

  return parsedDate.format(format);
};

export const getAPIDate = (value?: Date | null) => {
  if (value) {
    return dayjs(value).format("YYYY-MM-DD");
  }

  return dayjs().startOf("day").format("YYYY-MM-DD");
};

export const getPastAPIDate = (value: string, unit: ManipulateType = "month") =>
  dayjs().startOf("day").subtract(parseInt(value), unit).format("YYYY-MM-DD");

export const getOldestAPIDate = () => dayjs(0o0).format("YYYY-MM-DD");

export const isWithinTwoWeeks = (timestampString: string) => {
  const currentTime = dayjs();
  const twoWeeksAgo = currentTime.subtract(2, "week");
  const isWithinTwoWeeks = dayjs(timestampString).isBetween(twoWeeksAgo, currentTime, null, "[]");

  return isWithinTwoWeeks;
};

export const getDatesDelta = (startDate: ConfigType, endDate: ConfigType, unit: ManipulateType = "month") => {
  if (!startDate || !endDate) {
    return 0;
  }

  const start = dayjs(startDate);
  const end = dayjs(endDate);

  return end.diff(start, unit);
};

export const isWithinRange = (
  date: ConfigType,
  startDate: ConfigType,
  endDate: ConfigType,
  unit: ManipulateType = "day"
): boolean => {
  if (!startDate || !endDate) {
    return false;
  }

  return dayjs(date).isBetween(startDate, endDate, unit, "[]");
};

/**
 * Calculates the relative time passed from the provided UTC time to the current time using the dayjs library.
 *
 * @param {string} utcTime - The UTC time to calculate the relative time from. The time should be in ISO 8601 format.
 *
 * @returns {string} - Returns a string representing the relative time passed from the provided UTC time to the current time.
 *
 * @example
 * // Returns "a few seconds ago"
 * const relativeTime = timePassed("2022-01-01T00:00:00Z");
 *
 * @example
 * // Returns "5 minutes ago"
 * const relativeTime = timePassed("2022-01-01T00:05:00Z");
 */
export const timePassed = (utcTime: string) => {
  return dayjs(utcTime).fromNow();
};

export const isDateInPast = (date: ConfigType): boolean => {
  if (!date) {
    return false;
  }

  const parsedDate = dayjs(date);

  if (!parsedDate.isValid()) {
    return false;
  }

  return parsedDate.isBefore(dayjs(), "day");
};
