import { useQuery } from "@tanstack/react-query";
import { User } from "firebase/auth";

import { QUERIES } from "@Lib/constants/queries";
import { fetchMe, firebaseLogout } from "@Lib/services/user";
import { type ErrorCodes, CustomError } from "@Lib/types/error";
import { AccessLevels, TestPhases, type UserBEMergedWithFBData, type UserFE } from "@Lib/types/user";
import { formatError } from "@Lib/utils/errorHandlers";
import { showErrorNotification } from "@Lib/utils/notifications";

const temporarilyMapAccessLevel = (access_level: string): AccessLevels => {
  // map old BE access levels to new access levels; remove this once the BE is updated
  if (access_level == "TRIAL") {
    return "TRIAL";
  }
  if (access_level == "BASIC") {
    return "BASIC";
  }
  if (["CORE", "CUSTOMER"].includes(access_level)) {
    return "CORE";
  }
  if (["PRO", "PREMIUM"].includes(access_level)) {
    return "PRO";
  }
  if (["ENTERPRISE", "BETA"].includes(access_level)) {
    return "ENTERPRISE";
  }
  if (["INTERNAL", "ALPHA"].includes(access_level)) {
    return "INTERNAL";
  }
  return "CORE";
};
const temporarilyMapTestPhase = (access_level: string, test_phase?: string): TestPhases | undefined => {
  // map old BE access levels to new test phases; remove this once the BE is updated
  if (test_phase == "ALPHA" || access_level == "ALPHA") {
    return "ALPHA";
  }
  if (test_phase == "BETA" || access_level == "BETA") {
    return "BETA";
  }
};

const dataSelector = (userData: UserBEMergedWithFBData): UserFE => {
  if (!userData) {
    return null;
  }

  const {
    id,
    email,
    first_name,
    last_name,
    access_level,
    test_phase,
    has_subscriptions,
    organization_id,
    remaining_xlsx_user_downloads,
    remaining_pdf_user_downloads,
    remaining_pdf_organization_downloads,
    remaining_trial_days,
    ...fbUserFieldsWeUse
  } = userData;

  return {
    ...fbUserFieldsWeUse,
    fullName: first_name && last_name ? `${first_name} ${last_name}` : "",
    localId: id.toString(),
    email: email,
    firstName: first_name,
    lastName: last_name,
    accessLevel: temporarilyMapAccessLevel(access_level),
    testPhase: temporarilyMapTestPhase(access_level, test_phase),
    hasSubscriptions: has_subscriptions,
    organizationId: organization_id,
    remainingXlsxUserDownloads: remaining_xlsx_user_downloads,
    remainingPdfUserDownloads: remaining_pdf_user_downloads,
    remainingPdfOrganizationDownloads: remaining_pdf_organization_downloads,
    remainingTrialDays: remaining_trial_days,
  };
};

export const useFetchMe = (user: Nullable<User>, setLoginErrorCode: (errorCode: Nullable<ErrorCodes>) => void) =>
  useQuery({
    queryKey: QUERIES.getUserData(),
    queryFn: () => fetchMe(user),
    select: dataSelector,
    enabled: Boolean(user),
    initialData: null,
    retry: false,
    onSuccess: () => {
      setLoginErrorCode(null);
    },
    onError: (e: CustomError) => {
      firebaseLogout();
      setLoginErrorCode(e.status);
      showErrorNotification({ id: "query-error", message: formatError(e) });
    },
  });
