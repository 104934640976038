import { type ManipulateType } from "dayjs";

import { DATA_CONFIG } from "@Lib/config/filters/filterChipsConfig";
import { DATA_JOINER } from "@Lib/constants";
import { CustomOptionsENUM } from "@Lib/types/filters";
import { type FiltersStoreData, type DateRangeLocalFilterKeys } from "@Lib/types/filtersData";

import { getAPIDate, getPastAPIDate, getOldestAPIDate } from "./date";
import { getTypedKeys } from "./typedFromObject";

export const getDatesFromDropdownOption = (
  selectValeu: string
): {
  from: string;
  to: string;
} => {
  const [subtract, unit, handleOver] = selectValeu.split(DATA_JOINER);

  let from = getPastAPIDate(subtract, unit as ManipulateType);
  let to = getAPIDate();

  if (handleOver) {
    from = getOldestAPIDate();
    to = getPastAPIDate(subtract, unit as ManipulateType);
  }

  return {
    from,
    to,
  };
};

export const adjustSearchesData: (filters: FiltersStoreData) => FiltersStoreData = inputFilters => {
  const filters = { ...inputFilters };
  const filterKeys = getTypedKeys(filters);

  filterKeys.forEach(key => {
    if (/.*_range_max$/.test(key) && filters[key] === null) {
      filters[key] = Infinity as never;
    }

    if (/.*DateRangeLocal$/.test(key) && filters[key] !== CustomOptionsENUM.custom) {
      const parsedKey = key as DateRangeLocalFilterKeys;
      const value = filters[parsedKey];

      if (value) {
        const { from, to } = getDatesFromDropdownOption(value);
        const { dateDataFields } = DATA_CONFIG[parsedKey];

        filters[dateDataFields[0]] = from;
        filters[dateDataFields[1]] = to;
      }
    }
  });

  return filters;
};
