import { type TablesWithStore, type SortUI } from "@Lib/types/base";
import { resolveStoragePerPage } from "@Lib/utils/storage";

import { TableSortDirEnum } from "./api";
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_ITEMS_PER_PAGE_DETAILS } from "./ui";

/** Table settings */
export const CELL_MIN_WIDTH = 120;
export const NUMERIC_CELL_MIX_WIDTH = 50;
export const DATE_CELL_MIN_WIDTH = 80;
export const DATE_CELL_MAX_WIDTH = 90;
export const LOGO_CELL_WIDTH = 35;
export const SORT_ICONS_WIDTH = 8;
export const EMPTY_SORT = { column: null, order: null };
const DEFAULT_COMPANIES_SORT = {
  column: "funding",
  order: TableSortDirEnum.desc,
};
const DEFAULT_PORTFOLIO_SORT = {
  column: "latestDealDate",
  order: TableSortDirEnum.desc,
};
const DEFAULT_DEALS_SORT = {
  column: "date",
  order: TableSortDirEnum.desc,
};
const DEFAULT_INVESTORS_SORT = {
  column: "dealInvestedCount",
  order: TableSortDirEnum.desc,
};

const DEFAULT_FUNDS_SORT = {
  column: "latest_announcement_date",
  order: TableSortDirEnum.desc,
};

export const DEFAULT_SORTING: Record<TablesWithStore, SortUI> = {
  deals: DEFAULT_DEALS_SORT,
  companies: DEFAULT_COMPANIES_SORT,
  investors: DEFAULT_INVESTORS_SORT,
  funds: DEFAULT_FUNDS_SORT,
  projects: EMPTY_SORT,
  orgSubsidiaries: EMPTY_SORT,
  orgInvestors: EMPTY_SORT,
  orgDeals: DEFAULT_DEALS_SORT,
  orgProjects: EMPTY_SORT,
  orgPortfolio: DEFAULT_PORTFOLIO_SORT,
  orgInvestments: DEFAULT_DEALS_SORT,
  orgSimilarCompanies: EMPTY_SORT,
  orgFunds: DEFAULT_FUNDS_SORT,
};

export const DEFAULT_PER_PAGE: Record<TablesWithStore, number> = {
  deals: resolveStoragePerPage("deals", DEFAULT_ITEMS_PER_PAGE),
  companies: resolveStoragePerPage("companies", DEFAULT_ITEMS_PER_PAGE),
  investors: resolveStoragePerPage("investors", DEFAULT_ITEMS_PER_PAGE),
  funds: resolveStoragePerPage("funds", DEFAULT_ITEMS_PER_PAGE),
  projects: resolveStoragePerPage("projects", DEFAULT_ITEMS_PER_PAGE),
  orgSubsidiaries: DEFAULT_ITEMS_PER_PAGE_DETAILS,
  orgInvestors: DEFAULT_ITEMS_PER_PAGE_DETAILS,
  orgDeals: DEFAULT_ITEMS_PER_PAGE_DETAILS,
  orgProjects: DEFAULT_ITEMS_PER_PAGE_DETAILS,
  orgPortfolio: DEFAULT_ITEMS_PER_PAGE_DETAILS,
  orgInvestments: DEFAULT_ITEMS_PER_PAGE_DETAILS,
  orgSimilarCompanies: DEFAULT_ITEMS_PER_PAGE_DETAILS,
  orgFunds: DEFAULT_ITEMS_PER_PAGE_DETAILS,
};

export const SORT_FIELDS_MAP = {
  deals: {
    // Options: deal_date, deal_type_name, primary_sector_name, funding_usd
    date: "deal_date",
    stage: "deal_type_name",
    sectorName: "primary_sector_name",
    funding: "funding_usd",
  },
  companies: {
    // Options: name, stage, last_deal_date, funding, primary_sector_name, city_display_name, founded_date, company_size_range, status
    name: "name",
    latestStage: "stage",
    funding: "funding",
    sector: "primary_sector_name",
    location: "city_display_name",
    yearFounded: "founded_date",
    companySize: "company_size_range", // Doesn't seem to work
    status: "status",
    latestDealDate: "last_deal_date",
  },
  investors: {
    // Options: name, fund_size_range, is_lead, last_investment_date, city_display_name
    name: "name",
    fundSize: "fund_size_range",
    isLead: "is_lead",
    lastInvestment: "last_investment_date",
    location: "city_display_name",
    dealInvestedCount: "deal_invested_count",
  },
  projects: {
    name: "name",
    notableStatusName: "notable_status",
    sector: "sector",
    technology: "technology",
    progress: "progress",
    scale: "scale",
    location: "location",
    operationDate: "operation_date",
  },
  funds: {
    fundName: "fund_name",
    latest_announcement_date: "latest_announcement_date",
    latest_announcement_value: "latest_announcement_value",
    name: "name",
    relatedSize: "latest_announcement_value",
  },
};
