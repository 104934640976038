import { FC } from "react";

import { LoadingOverlay } from "@mantine/core";

import { Z_INDEX_MAP } from "@Lib/constants/ui";

import LoadingLogo from "./LoadingLogo";

type LoaderProps = {
  visible?: boolean;
  overlayBlur?: number;
};

const Loader: FC<LoaderProps> = ({ visible = true, overlayBlur = 6 }) => {
  return (
    <LoadingOverlay visible={visible} overlayBlur={overlayBlur} zIndex={Z_INDEX_MAP.loader} loader={<LoadingLogo />} />
  );
};

export default Loader;
