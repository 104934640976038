import i18n, { use as i18nextUse } from "i18next";
import { initReactI18next } from "react-i18next";

import englighTranslation from "./locales/en/translation.json";

const resources = {
  en: {
    translations: englighTranslation,
  },
};

i18nextUse(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources,
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en"];

type TranslationResources = typeof resources.en.translations;
type DeepTranslationKeys<T, Prefix extends string = ""> =
  T extends Record<string, unknown>
    ? {
        [K in keyof T]: DeepTranslationKeys<T[K], `${Prefix & string}${Prefix extends "" ? "" : "."}${K & string}`>;
      }[keyof T]
    : Prefix;

export type TranslationKeys = DeepTranslationKeys<TranslationResources>;

export default i18n;
