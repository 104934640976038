import { forwardRef } from "react";

import { BoxProps } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { SC_CONTEXT_CONFIG } from "@Lib/config/sectorCompass";
import { DOWNLOAD_IDS } from "@Lib/constants/ui";
import { useDownloadHTMLChunk } from "@Lib/hooks/common";
import { type SectorCompassUI } from "@Lib/types/sectorCompass";

import SectionHolder from "../SectionHolder";

import ContextContainer from "./ContextContainer";
import ContextParagraph from "./ContextParagraph";

type ContextSectionProps = BoxProps & {
  data: SectorCompassUI;
};

const ContextSection = forwardRef<HTMLDivElement, ContextSectionProps>(({ data, ...boxProps }, ref) => {
  const { t } = useTranslation();

  const title = t("sectorCompassPage.sections.context.title");
  const { handleDownload, isLoading: isDownloading } = useDownloadHTMLChunk({
    elementId: DOWNLOAD_IDS.contextId,
    fileName: `${data.name} - ${title}`,
    branded: true,
  });

  return (
    <SectionHolder ref={ref} {...boxProps} title={title} onDownload={handleDownload} isDownloading={isDownloading}>
      <ContextContainer downloadId={DOWNLOAD_IDS.contextId}>
        {SC_CONTEXT_CONFIG.map((titleKey, index) => (
          <ContextParagraph key={index} titleKey={titleKey} content={data[titleKey]} />
        ))}
      </ContextContainer>
    </SectionHolder>
  );
});

export default ContextSection;
