import { forwardRef } from "react";

import { BoxProps } from "@mantine/core";
import { useTranslation } from "react-i18next";

import ResponsiveWrapper from "@Components/charts/common/ResponsiveWrapper";
import Divider from "@Components/Divider/Divider";
import PageSection from "@Components/PageSection/PageSection";
import withAccessWrapper, { type WithAccessWrapperProps } from "@Components/user/withAccessWrapper";

import DeploymentMap from "./DeploymentMap";
import DownloadButton from "./DownloadButton";
import Filters from "./Filters";
import ProjectsCountryChart from "./ProjectsCountryChart";
import ProjectsOperationalDateChart from "./ProjectsOperationalDateChart";
import ProjectsProgressChart from "./ProjectsProgressChart";
import ProjectsScaleChart from "./ProjectsScaleChart";
import ProjectsTechnologyChart from "./ProjectsTechnologyChart";
import RaceToScale from "./RaceToScale";
import RaceToScaleCompanies from "./RaceToScaleCompanies";

type DeploymentSectionProps = WithAccessWrapperProps &
  BoxProps & {
    sectorId: number;
    marketName: string;
    sectorPath: string[];
    projectsStartDate: number;
    projectsEndDate: number;
  };

const DeploymentSection = forwardRef<HTMLDivElement, DeploymentSectionProps>(
  ({ sectorId, marketName, sectorPath, projectsStartDate, projectsEndDate, ...boxProps }, ref) => {
    const { t } = useTranslation();

    const title = t("sectorCompassPage.sections.deployment.title");

    return (
      <PageSection
        ref={ref}
        title={title}
        isPreview
        buttons={[
          <Filters key="filters" projectsStartDate={projectsStartDate} projectsEndDate={projectsEndDate} />,
          <DownloadButton key="charts-download" sectorId={sectorId} marketName={marketName} />,
        ]}
        {...boxProps}
      >
        <DeploymentMap sectorId={sectorId} sectorPath={sectorPath} />
        <Divider m={0} />
        <ResponsiveWrapper>
          <ProjectsProgressChart sectorId={sectorId} />
          <ProjectsScaleChart sectorId={sectorId} />
        </ResponsiveWrapper>
        <Divider m={0} />
        <ResponsiveWrapper>
          <ProjectsTechnologyChart sectorId={sectorId} />
          <ProjectsCountryChart sectorId={sectorId} />
        </ResponsiveWrapper>
        <Divider m={0} />
        <ProjectsOperationalDateChart sectorId={sectorId} sectorPath={sectorPath} />
        <Divider m={0} />
        <RaceToScale sectorId={sectorId} />
        <Divider m={0} />
        <RaceToScaleCompanies sectorId={sectorId} />
      </PageSection>
    );
  }
);

export default withAccessWrapper(DeploymentSection);
