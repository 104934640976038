import { FC } from "react";

import { Box, Button, Flex, Text } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { useTranslation } from "react-i18next";

import { BOOK_CALL_MODAL_WIDTH } from "@Lib/constants/ui";

type BookCallModalProps = ContextModalProps<{
  requestCall: () => void;
  translationKey?: "available" | "default";
}>;

const BookCallModal: FC<BookCallModalProps> = ({ context, id, innerProps }) => {
  const { t } = useTranslation();

  const { requestCall, translationKey = "default" } = innerProps;

  const handleConfirmClick = () => {
    requestCall();
    context.closeModal(id);
  };

  const handleCloseClick = () => {
    context.closeModal(id);
  };

  return (
    <Box w={BOOK_CALL_MODAL_WIDTH}>
      <Text pr={"sm"}>{t(`modals.bookCall.message.${translationKey}`)}</Text>
      <Flex gap={"xs"} mt={"md"} justify="flex-end">
        <Button variant="outline" onClick={handleCloseClick}>
          {t("modals.bookCall.cancel")}
        </Button>
        <Button onClick={handleConfirmClick}>{t("modals.bookCall.confirm")}</Button>
      </Flex>
    </Box>
  );
};

export default BookCallModal;
