import { forwardRef } from "react";

import { Center, Box, BoxProps } from "@mantine/core";
import { useTranslation } from "react-i18next";

import ErrorBox from "@Components/ErrorBox/ErrorBox";
import { GoToLink, LinkRow } from "@Components/GoToLink";
import { ROUTES } from "@Lib/constants";
import { DOWNLOAD_IDS } from "@Lib/constants/ui";
import { useDownloadHTMLChunk } from "@Lib/hooks/common";
import { useGetCompassMap } from "@Lib/hooks/sectorCompass";
import { useFiltersStore } from "@Lib/store/filters/data";

import SectionHolder from "../SectionHolder";

import MarkepMap from "./MarkepMap";

type MarkepMapSectionProps = BoxProps & {
  compassId: string;
  marketName: string;
  sectorPath: string[];
};

const MarkepMapSection = forwardRef<HTMLDivElement, MarkepMapSectionProps>(
  ({ compassId, marketName, sectorPath, ...boxProps }, ref) => {
    const { t } = useTranslation();
    const setTaxonomyFilter = useFiltersStore(state => state.setTaxonomyFilter);
    const clearFilters = useFiltersStore(state => state.clearFilters);

    const { isLoading, isError, data } = useGetCompassMap(compassId);

    const title = t("sectorCompassPage.sections.map.title");
    const { handleDownload, isLoading: isDownloading } = useDownloadHTMLChunk({
      elementId: DOWNLOAD_IDS.marketMapId,
      fileName: `${marketName} - ${title}`,
    });

    const handleLinkClick = () => {
      clearFilters();
      setTaxonomyFilter({
        action: "add",
        filterField: "sector_id",
        localField: "companySectorIdsLocal",
        value: sectorPath.join("_"),
      });
    };

    return (
      <SectionHolder ref={ref} {...boxProps} title={title} onDownload={handleDownload} isDownloading={isDownloading}>
        {isLoading ? (
          <Center style={{ flex: 1 }}>{t("sectorCompassPage.sections.map.loading")}</Center>
        ) : isError ? (
          <ErrorBox text={t("sectorCompassPage.sections.map.errorText")} />
        ) : (
          <MarkepMap downloadId={DOWNLOAD_IDS.marketMapId} data={data} />
        )}

        <Box onClick={handleLinkClick}>
          <LinkRow>
            <GoToLink to={ROUTES.companies} text={t("sectorCompassPage.sections.map.link", { marketName })} />
          </LinkRow>
        </Box>
      </SectionHolder>
    );
  }
);

export default MarkepMapSection;
