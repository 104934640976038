import { FC } from "react";

import { Menu, Button, ScrollArea, createStyles, MenuProps, ButtonProps } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import ChevronDownIcon from "@Assets/chevron-down.svg?react";
import ErrorBoundary from "@Components/ErrorBoundary";
import { DROPDOWN_MAX_HEIGHT } from "@Lib/constants";

const useStyles = createStyles((theme, opened: boolean) => ({
  outline: {
    color: theme.colors.grey[9],
    fontWeight: theme.other.fontWeight.regular,
    border: theme.other.getDefaultBorder(theme, opened),
    "&:hover": {
      color: theme.colors.grey[9],
      backgroundColor: theme.white,
      border: theme.other.getDefaultBorder(theme, opened),
    },
  },
  filled: {},
  hightlight: {
    backgroundColor: theme.colors.teal[1],
    "&:hover": {
      backgroundColor: theme.colors.teal[0],
    },
  },
}));

type DropdownProps = MenuProps & {
  btnText: string;
  variant?: "outline" | "filled";
  isLoading?: boolean;
  closeOnPosFlip?: boolean;
  buttonTargetProps?: ButtonProps;
  hightlight?: boolean;
  h?: number;
};

const Dropdown: FC<DropdownProps> = ({
  children,
  btnText,
  closeOnPosFlip,
  variant = "filled",
  isLoading,
  buttonTargetProps,
  hightlight,
  ...menuProps
}) => {
  const [opened, handlers] = useDisclosure(false);
  const { classes, cx } = useStyles(opened);

  const handleCloseOnPosFlip = closeOnPosFlip ? handlers.close : undefined;

  return (
    <Menu
      position="bottom"
      offset={4}
      closeOnItemClick={false}
      opened={opened}
      onClose={handlers.close}
      onOpen={handlers.open}
      onPositionChange={handleCloseOnPosFlip}
      {...menuProps}
    >
      <Menu.Target>
        <Button
          rightIcon={<ChevronDownIcon width={"1.2em"} />}
          variant={variant}
          className={cx(classes[variant], { [classes["hightlight"]]: hightlight })}
          loading={isLoading}
          {...buttonTargetProps}
        >
          {btnText}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <ScrollArea.Autosize maxHeight={DROPDOWN_MAX_HEIGHT} pos="relative">
          <ErrorBoundary>{children}</ErrorBoundary>
        </ScrollArea.Autosize>
      </Menu.Dropdown>
    </Menu>
  );
};

export default Dropdown;
