import { type PaginationDataPayload } from "@Lib/types/base";
import { type SightlinesResponse, type SightlineBE, type FactorBE } from "@Lib/types/sightlines";

import { goldApi } from "./api";

//TODO: Change the endpoint string when BE changes
export const getAllSightlines = async <T = SightlinesResponse>({
  page,
  perPage,
  filters,
}: Omit<PaginationDataPayload, "sorting">) => {
  const { data } = await goldApi.get<T>("/insights", {
    params: {
      page,
      page_size: perPage,
      ...filters,
    },
  });

  return data;
};

//TODO: Change the endpoint string when BE changes
export const getSightlineById = async (sightlineId: string) => {
  const { data } = await goldApi.get<SightlineBE>(`/insights/${sightlineId}`);

  return data;
};

export const getSightlineReadinessFactors = async () => {
  const { data } = await goldApi.get<FactorBE[]>("/factors");

  return data;
};
