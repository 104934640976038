import {
  type SectorFilterResponse,
  type CheckboxFilterResponse,
  type EntityFilterResponse,
  type LocationBE,
} from "@Lib/types/filters";
import { getAPIHandler, getAPIPaginatedHandler } from "@Lib/utils/api";

import { goldApi } from "./api";

export const getTaxonomyFilterData = getAPIHandler<SectorFilterResponse>("/taxonomy/sectors");

export const getDealTypesFilterData = getAPIHandler<CheckboxFilterResponse>("/taxonomy/deal-types");

export const getCountryFilterData = getAPIPaginatedHandler<EntityFilterResponse>("/countries");

export const getCompanyStatusesFilterData = getAPIHandler<EntityFilterResponse>("/statuses");

export const getCompanyStagesFilterData = getAPIHandler<EntityFilterResponse>("/stages");

export const getTechTypeFilterData = getAPIHandler<EntityFilterResponse>("/tech-types");

export const getCompanySizeFilterData = getAPIHandler<EntityFilterResponse>("/company-sizes");

export const getInvestorTypeFilterData = getAPIHandler<CheckboxFilterResponse>("/taxonomy/investor-types");

export const getFundSizesFilterData = getAPIPaginatedHandler<EntityFilterResponse>("/fund-sizes");

export const getSightlineImpactsFilterData = getAPIHandler<EntityFilterResponse>("/impacts");

export const getSightlineFactorsFilterData = getAPIHandler<EntityFilterResponse>("/factors");

export const getGeographyFilterData = async (search?: string) => {
  const { data } = await goldApi.get<EntityFilterResponse>("/locations", {
    params: {
      page: 1,
      page_size: 0,
      search,
    },
  });

  return data;
};

export const getGeographyLocation = async (locationId: string) => {
  const { data } = await goldApi.get<LocationBE>(`/locations/${locationId}`);

  return data;
};

export const getProjectStatusFilterData = getAPIPaginatedHandler<EntityFilterResponse>("/progresses");

export const getProjectScaleFilterData = getAPIPaginatedHandler<EntityFilterResponse>("/scales");
