import { FC, ReactNode } from "react";

import { Box, Center, Image, Text, createStyles, ImageProps, BoxProps } from "@mantine/core";

import RightArrow from "@Assets/r-arrow.svg?react";
import TrackRouteLink from "@Components/TrackRouteLink/TrackRouteLink";

const useStyles = createStyles((theme, isSightline: boolean) => ({
  root: {
    ...theme.other.getLinkStyle(theme),
    display: "flex",
    gap: theme.spacing.sm,
    padding: theme.spacing.sm,
    fontWeight: theme.other.fontWeight.bold,
    border: theme.other.getDefaultBorder(theme),
    borderRadius: theme.radius.xs,
    minHeight: 130,
    maxHeight: 130,
    backgroundColor: theme.white,
    flex: 1,
    overflow: "hidden",
  },
  imageContainer: {
    border: isSightline ? "none" : theme.other.getDefaultBorder(theme),
    borderRadius: theme.radius.xs,
    padding: isSightline ? "none" : "12px",
  },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  titleRow: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.colors.grey[9],
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fontWeight.semiBold,
  },
  subtitle: {
    color: theme.colors.grey[9],
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fontWeight.regular,
  },
  linkRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    gap: theme.spacing.xs,
  },
  date: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.grey[6],
    fontWeight: theme.other.fontWeight.regular,
    whiteSpace: "nowrap",
  },
  link: {
    display: "inline-flex",
    alignItems: "center",
    borderRadius: theme.radius.xs,
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fontWeight.semiBold,
    flexWrap: "nowrap",
  },
}));

const sightlineImageProps: ImageProps = {
  width: 120,
  height: 97,
  fit: "cover",
};

type CardProps = BoxProps & {
  imgSrc?: string;
  chips?: ReactNode;
  title: string;
  subtitle: string;
  date: Nullable<string>;
  linkText: string;
  goto: string;
  isSightline?: boolean;
};

const Card: FC<CardProps> = ({
  imgSrc,
  chips,
  title,
  subtitle,
  date,
  linkText,
  goto,
  isSightline = false,
  ...boxProps
}) => {
  const { classes } = useStyles(isSightline);

  return (
    <Box to={goto} component={TrackRouteLink} className={classes.root} {...boxProps}>
      {imgSrc && (
        <Center className={classes.imageContainer}>
          <Image
            src={imgSrc}
            fit="contain"
            width={73}
            height={73}
            /* Override image props if sightline */
            {...(isSightline && sightlineImageProps)}
          />
        </Center>
      )}
      <Box className={classes.content}>
        {chips}
        <Box className={classes.titleRow}>
          <Text className={classes.title} lineClamp={1}>
            {title}
          </Text>
          <Text className={classes.subtitle} lineClamp={1}>
            {subtitle}
          </Text>
        </Box>
        <Box className={classes.linkRow}>
          <Text className={classes.date}>{date}</Text>
          <Text className={classes.link}>
            {linkText}
            &nbsp;
            <RightArrow />
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Card;
