import { FiltersStoreSightlines } from "@Lib/types/filtersSightline";

import { getFiltersStore, createFilterActiveSelector, getAPIFiltersSelector } from "../factories/filters";

export const useFiltersStore = getFiltersStore<FiltersStoreSightlines>("Sightlines Filters");

/** Universal Group Filters */
const universalFields = new Set(["sectorIdsLocal", "sector_id"]);

/** Sightline Topic Group Filters */
const sightlineSectorReadinessFields = new Set(["factor_id", "impact"]);

const allFilterFields = new Set([...universalFields, ...sightlineSectorReadinessFields]);

export const hasActiveFilterSelector = createFilterActiveSelector(allFilterFields);
export const universalFilterActiveSelector = createFilterActiveSelector(universalFields);
export const readinessFilterActiveSelector = createFilterActiveSelector(sightlineSectorReadinessFields);

/**
 * Keys that must not be sent to BE
 */
const uiFieldsSet = new Set(["sectorIdsLocal"]);

export const filterForAPISelector = getAPIFiltersSelector(uiFieldsSet);
