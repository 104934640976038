import {
  type FiltersStoreData,
  type UniversalGroupFilters,
  type DealsGroupFilters,
  type CompanyGroupFilters,
  type InvestorGroupFilters,
  type ProjectGroupFilters,
} from "@Lib/types/filtersData";
import { type KeyMatchingPartial } from "@Lib/types/utils";

import { getFiltersStore, createFilterActiveSelector, getAPIFiltersSelector } from "../factories/filters";

export const useFiltersStore = getFiltersStore<FiltersStoreData>("Data Filters");

/** Universal Group Filters */
const universalFields = new Set<keyof UniversalGroupFilters>(["companySectorIdsLocal", "sector_id"]);

/** Deals Group Filters */
const dealsFields = new Set<keyof DealsGroupFilters>([
  "dealTypesIdsLocal",
  "deal_type_id",
  "dealSizeRangeLocal",
  "deal_funding_range_min",
  "deal_funding_range_max",
  "dealDateRangeLocal",
  "deal_date_range_after",
  "deal_date_range_before",
]);

/** Company Group Filters */
const companyFields = new Set<keyof CompanyGroupFilters>([
  "company_location_id",
  "company_id",
  "company_status_id",
  "company_stage_id",
  "companyFundingRangeLocal",
  "company_total_funding_range_min",
  "company_total_funding_range_max",
  "company_tech_type_id",
  "companyFoundedDateRangeLocal",
  "company_founded_date_range_after",
  "company_founded_date_range_before",
  "companyLatestDealDateRangeLocal",
  "company_last_deal_date_range_after",
  "company_last_deal_date_range_before",
  "company_size_id",
]);

/** Investor Group Filters */
const investorFields = new Set<keyof InvestorGroupFilters>([
  "investor_location_id",
  "investor_id",
  "investorTypesIdsLocal",
  "investor_type_id",
  "investor_fund_size_id",
  "investor_is_lead",
  "investmentsRangeLocal",
  "investor_num_investments_range_min",
  "investor_num_investments_range_max",
  "leadInvestmentsRangeLocal",
  "investor_num_lead_investments_range_min",
  "investor_num_lead_investments_range_max",
  "lastInvestmentDateRangeLocal",
  "investor_last_investment_date_range_after",
  "investor_last_investment_date_range_before",
]);

/** Project Group Filters */
const projectFields = new Set<keyof ProjectGroupFilters>([
  "project_location_id",
  "project_progress_id",
  "project_scale_id",
  "projectOperationDateRangeLocal",
  "project_operation_date_range_after",
  "project_operation_date_range_before",
]);

const allFilterFields = new Set([
  ...universalFields,
  ...dealsFields,
  ...companyFields,
  ...investorFields,
  ...projectFields,
]);

export const hasActiveFilterSelector = createFilterActiveSelector(allFilterFields);
export const universalFilterActiveSelector = createFilterActiveSelector(universalFields);
export const dealsFilterActiveSelector = createFilterActiveSelector(dealsFields);
export const companyFilterActiveSelector = createFilterActiveSelector(companyFields);
export const investorFilterActiveSelector = createFilterActiveSelector(investorFields);
export const projectFilterActiveSelector = createFilterActiveSelector(projectFields);

/**
 * Keys that must not be sent to BE
 */
const uiFieldsSet = new Set<KeyMatchingPartial<FiltersStoreData, "Local">>([
  "companySectorIdsLocal",
  "dealTypesIdsLocal",
  "dealSizeRangeLocal",
  "dealDateRangeLocal",
  "companyIdLocal",
  "companyFundingRangeLocal",
  "companyFoundedDateRangeLocal",
  "companyLatestDealDateRangeLocal",
  "investmentsRangeLocal",
  "investorIdLocal",
  "leadInvestmentsRangeLocal",
  "lastInvestmentDateRangeLocal",
  "investorTypesIdsLocal",
  "projectOperationDateRangeLocal",
]);

export const filterForAPISelector = getAPIFiltersSelector(uiFieldsSet);
