import { FC } from "react";

import { Box, Button, Flex, Text } from "@mantine/core";
import { closeAllModals, ContextModalProps } from "@mantine/modals";
import { useTranslation } from "react-i18next";

import { useUserTrackingContext } from "@Context/UserTracking";
import { LOGOUT_MODAL_WIDTH } from "@Lib/constants";
import { useSignOut } from "@Lib/hooks/user";
import { useFiltersStore as useDataFiltersStore } from "@Lib/store/filters/data";
import { useFiltersStore as useSightlinesFilterStore } from "@Lib/store/filters/sightlines";

const LogoutModal: FC<ContextModalProps> = ({ context, id }) => {
  const { t } = useTranslation();
  const { mutate: signOut } = useSignOut();

  const { trackLogout } = useUserTrackingContext();

  const clearSightlineFilters = useSightlinesFilterStore(state => state.clearFilters);
  const clearDataFilters = useDataFiltersStore(state => state.clearFilters);

  const handleConfirmClick = () => {
    closeAllModals();
    trackLogout();
    signOut();
    clearSightlineFilters();
    clearDataFilters();
  };

  const handleCloseClick = () => {
    context.closeModal(id);
  };

  return (
    <Box w={LOGOUT_MODAL_WIDTH}>
      <Text align="center" pt={"xs"}>
        {t("modals.logout.title")}
      </Text>
      <Flex gap={"xs"} pt={"sm"}>
        <Button variant="outline" fullWidth onClick={handleCloseClick}>
          {t("modals.logout.cancel")}
        </Button>
        <Button fullWidth onClick={handleConfirmClick}>
          {t("modals.logout.confirm")}
        </Button>
      </Flex>
    </Box>
  );
};

export default LogoutModal;
