const DATA = "/data";
const RESEARCH = "/research";
const INSIGHTS = "/insights";
const SIGHTLINES = "/sightlines";
const PUBLIC = "/public";
const METHODOLOGY = "/methodology";

// To generate urls from ROUTES with slugs use `generateLinkUrl` from "@Lib/utils/routes"
// ex. generateLinkUrl("companyById", id)
export const ROUTES = {
  root: "/",
  /** Auth routes */
  login: "/login",
  resetPass: "/reset-password",
  signup: "/signup",
  home: "/home",
  /** Public routes */
  public: PUBLIC,
  capitalStack: `${PUBLIC}/capital-stack`,
  /** Data routes */
  data: DATA,
  deals: `${DATA}/deals`,
  companies: `${DATA}/companies`,
  investors: `${DATA}/investors`,
  organizations: `${DATA}/organizations`,
  organizationById: `${DATA}/organizations/:organizationId`,
  funds: `${DATA}/funds`,
  projects: `${DATA}/projects`,
  projectById: `${DATA}/projects/:projectId`,
  /** Research routes */
  research: RESEARCH,
  compass: `${RESEARCH}/sector-compass`,
  compassById: `${RESEARCH}/sector-compass/:compassId`,
  /** Sightlines */
  sightlines: SIGHTLINES,
  sightlineById: `${SIGHTLINES}/:sightlineId`,
  /** Investment */
  investment: "/investment",
  /** Methodology */
  methodology: METHODOLOGY,
  faq: `${METHODOLOGY}/faq`,
  definitions: `${METHODOLOGY}/definitions`,
  // The below are raplaced by `organizationById`
  // DON'T USE
  investorById: `${DATA}/investors/:investorId`, // kept for redirecting to organizationById
  companyById: `${DATA}/companies/:companyId`, // kept for redirecting to organizationById
  insights: INSIGHTS, //kept for redirecting to /sightlines
  insightById: `${INSIGHTS}/:insightId`, //kept for redirecting to //sightlines/:id
};

export const ROUTE_TO_PAGE_NAME_MAP = {
  [ROUTES.home]: "Home Page",
  [ROUTES.signup]: "Signup Page",
  /** Public routes */
  [ROUTES.capitalStack]: "Capital Stack Page",
  /** Data routes */
  [ROUTES.deals]: "Deals Page",
  [ROUTES.companies]: "Companies Page",
  [ROUTES.investors]: "Investors Page",
  [ROUTES.organizationById]: "Organization Details Page",
  [ROUTES.projects]: "Projects Page",
  [ROUTES.projectById]: "Project Details Page",
  /** Research routes */
  [ROUTES.compass]: "Sector Compass Page",
  [ROUTES.compassById]: "Sector Compass Market Page",
  /** Sightlines */
  [ROUTES.sightlines]: "Sightlines list Page",
  [ROUTES.sightlineById]: "Sightline Page",
  /** Investment */
  [ROUTES.investment]: "Investment Dashboard Page",
};

export const PUBLIC_ROUTES = new Set([ROUTES.root, ROUTES.login, ROUTES.resetPass, ROUTES.signup, ROUTES.capitalStack]);
export const PUBLIC_LANDING_ROUTES = new Set([ROUTES.signup, ROUTES.capitalStack]);
