import { DATA_JOINER } from "@Lib/constants";
import { type ChartDatum } from "@Lib/types/base";
import { CustomOptionsENUM } from "@Lib/types/filters";
import { type FiltersStoreData } from "@Lib/types/filtersData";
import { type ChartsStoreFilters } from "@Lib/types/investmentDash";
import { type KeyMatchingPartial } from "@Lib/types/utils";
import { buildCompositeIdsFromNestedObject, buildNestedObject, getDeepestKeys } from "@Lib/utils/taxonomyHandlers";

import { getFiltersStore, getAPIFiltersSelector } from "../factories/filters";

// Common filters for all charts
export const useFiltersStore = getFiltersStore<ChartsStoreFilters>("Investment Page Filters");
export const useSavedSearchFiltersStore = getFiltersStore<FiltersStoreData>("Investment Page Saved Search Filters");

/**
 * Keys that must not be sent to BE
 */
const uiFieldsSet = new Set<KeyMatchingPartial<ChartsStoreFilters, "Local">>([
  "dealTypeIdsLocal",
  "companyPrimarySectorIdsLocal",
]);

export const filterForAPISelector = getAPIFiltersSelector<ChartsStoreFilters>(uiFieldsSet);

/**
 * Keys that must not be sent to BE
 */
const uiSavedSearchFieldsSet = new Set<KeyMatchingPartial<FiltersStoreData, "Local">>([
  "companySectorIdsLocal",
  "dealTypesIdsLocal",
  "dealSizeRangeLocal",
  "dealDateRangeLocal",
  "companyIdLocal",
  "companyFundingRangeLocal",
  "companyFoundedDateRangeLocal",
  "companyLatestDealDateRangeLocal",
  "investmentsRangeLocal",
  "investorIdLocal",
  "leadInvestmentsRangeLocal",
  "lastInvestmentDateRangeLocal",
  "investorTypesIdsLocal",
  "projectOperationDateRangeLocal",
]);

export const filterSavedSearchForAPISelector = getAPIFiltersSelector(uiSavedSearchFieldsSet);

// Method to combine filters from both the main filters store and the saved search filters store using an AND condition.
export const combineFilters = (savedSearchFilters: FiltersStoreData, dashFilters: ChartsStoreFilters) => {
  const searches = { ...savedSearchFilters };
  // The below filters are handled inside the `DateRangeFilter` component, since it calculates its state,
  // based on which start and end dates are taken from the groups selection
  delete searches.deal_date_range_after;
  delete searches.deal_date_range_before;

  delete searches.deal_type_id;
  delete searches.company_location_id;
  delete searches.sector_id;

  return { ...searches, ...dashFilters };
};

export const translateDataFiltersToDashFilters = (
  dataFilters: FiltersStoreData,
  dashFilters: ChartsStoreFilters,
  dashStagesConfig: ChartDatum[]
): ChartsStoreFilters => {
  const resultFilters = { ...dashFilters };
  // Logic to combine filters using an AND condition

  // Date range
  // Fields are hendled inside `DateRangeFilter` component, which sets them inside `dashFilters` store
  // These fileds are deleted from `dataFilters` inside `combineFilters` API handler

  // Location
  if ("company_location_id" in dataFilters) {
    resultFilters.company_location_id = dataFilters.company_location_id;
  }

  // Taxonomy
  if ("sector_id" in dataFilters) {
    resultFilters.company_primary_sector_id = dataFilters.sector_id;
    resultFilters.companyPrimarySectorIdsLocal = dataFilters.companySectorIdsLocal;
  }

  // Stage
  if (dataFilters["dealTypesIdsLocal"]) {
    const stageNestedObject = dataFilters.dealTypesIdsLocal;
    const stageCompositeIds = buildCompositeIdsFromNestedObject(stageNestedObject);
    const stageCompositeIdsSet = new Set(stageCompositeIds);
    resultFilters.deal_type_id = [];
    resultFilters.dealTypeIdsLocal = [];

    for (const stageConfig of dashStagesConfig) {
      if (stageConfig.display !== "bar") {
        continue;
      }

      const matchedIds = stageConfig.sectorTaxonomyIds
        .filter((id: string) => stageCompositeIdsSet.has(id) || stageCompositeIdsSet.has(id.split(DATA_JOINER)[0]))
        .map(sectorTaxonomyIds => `${stageConfig.groupId}${DATA_JOINER}${sectorTaxonomyIds}`);

      if (matchedIds.length) {
        resultFilters.deal_type_id.push(stageConfig.groupId);
        resultFilters.dealTypeIdsLocal.push(...matchedIds);
      }
    }
  }

  return resultFilters;
};

export const translateDashFiltersToDataFilters = (
  dataFilters: FiltersStoreData,
  dashFilters: ChartsStoreFilters,
  dashStagesConfig: ChartDatum[],
  stageCompositeIds: string[]
): FiltersStoreData => {
  const resultFilters = { ...dataFilters };

  // Date range
  if ("deal_date_range_after" in dashFilters || "deal_date_range_before" in dashFilters) {
    resultFilters.deal_date_range_after = dashFilters.deal_date_range_after;
    resultFilters.deal_date_range_before = dashFilters.deal_date_range_before;
    resultFilters.dealDateRangeLocal = CustomOptionsENUM.custom;
  }

  // Location
  if ("company_location_id" in dashFilters) {
    resultFilters.company_location_id = dashFilters.company_location_id;
  }

  // Taxonomy
  if ("company_primary_sector_id" in dashFilters) {
    resultFilters.sector_id = dashFilters.company_primary_sector_id;
    resultFilters.companySectorIdsLocal = dashFilters.companyPrimarySectorIdsLocal;
  }

  // Stage
  const compositeIds = [...stageCompositeIds];
  if (stageCompositeIds.length === 0) {
    const stageIds = dashFilters.deal_type_id;

    // Extract composite ids per each selected stage
    for (const config of dashStagesConfig) {
      if (config.display !== "bar") {
        continue;
      }

      if (!stageIds) {
        compositeIds.push(...config.sectorTaxonomyIds);
      } else if (stageIds.includes(config.groupId)) {
        compositeIds.push(...config.sectorTaxonomyIds);
      }
    }
  }

  // Build the taxonomy nested object
  const taxonomyTree = compositeIds.reduce((accum, id) => buildNestedObject(id.split(DATA_JOINER), accum), {});
  resultFilters.dealTypesIdsLocal = taxonomyTree;
  // Get filtration ids
  resultFilters.deal_type_id = getDeepestKeys(taxonomyTree);

  return resultFilters;
};
