import { FC } from "react";

import { createStyles } from "@mantine/core";

import ChevronIcon from "@Assets/chevron.svg?react";

const useStyles = createStyles({
  buttonIcon: {
    width: 20,
    height: 20,
    transform: "rotateZ(90deg)",
  },
});

type ChevronButtonIconProps = {
  className?: string;
};

const ChevronButtonIcon: FC<ChevronButtonIconProps> = ({ className }) => {
  const { classes, cx } = useStyles();
  return <ChevronIcon className={cx(classes.buttonIcon, className)} />;
};

export default ChevronButtonIcon;
