import { createContext, useContext } from "react";

export type FilterDrawerContextValues = {
  opened: boolean;
  toggleFilterDrawer: () => void;
  openFilterDrawer: () => void;
  closeFilterDrawer: () => void;
};

export const FilterDrawerContext = createContext<FilterDrawerContextValues>(null!);

FilterDrawerContext.displayName = "FilterDrawerContext";

export const useFilterDrawerContext = () => useContext(FilterDrawerContext);
