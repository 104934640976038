import { useState, useMemo, useCallback, useEffect, FC } from "react";

import { Box, Text, createStyles, SelectItem } from "@mantine/core";
import { useTranslation } from "react-i18next";

import NoDataMessageBase from "@Components/charts/common/NoDataMessageBase";
import ErrorBox from "@Components/ErrorBox/ErrorBox";
import InfoButton from "@Components/InfoButton";
import ItemsPerPageSelect from "@Components/ItemsPerPageSelect/ItemsPerPageSelect";
import Loader from "@Components/Loader/Loader";
import { DOWNLOAD_IDS, DOWNLOAD_IGNORE_CLASS } from "@Lib/constants/ui";
import { useGetProjectScaleFilterData } from "@Lib/hooks/filters";
import { useGetRaceToScaleProjects } from "@Lib/hooks/sectorCompass";
import { useDeploymentFilterStore } from "@Lib/store/sectorCompass/deployment";

import { LegendContent, RtSInfoContent } from "../common";
import { HEADER_ROW_HEIGHT } from "../common/utils";

import { RaceToScaleProvider } from "./context";
import RaceToScaleGrid from "./RaceToScaleGrid";

const ITEMS_PER_PAGE_OPTIONS = ["20", "50", "100"];
const ROW_HEIGHT = 30;
const MAX_ROWS = parseInt(ITEMS_PER_PAGE_OPTIONS[0]);

const useStyles = createStyles((theme, { rows, perPageVisible }: { rows: number; perPageVisible: boolean }) => {
  return {
    container: {
      fontSize: theme.fontSizes.sm,
      flexShrink: 0,
      flexBasis: "100%",
      width: "100%",
    },
    titleRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      padding: theme.spacing.sm,
    },
    title: {
      display: "flex",
      alignItems: "center",
      fontWeight: theme.other.fontWeight.bold,
    },
    perPageSelect: perPageVisible
      ? {}
      : {
          visibility: "hidden",
          pointerEvents: "none",
        },
    content: {
      minHeight: rows * ROW_HEIGHT + HEADER_ROW_HEIGHT,
      paddingInline: theme.spacing.sm,
      position: "relative",
    },
  };
});

type RaceToScaleProps = {
  sectorId: number;
};

const RaceToScale: FC<RaceToScaleProps> = ({ sectorId }) => {
  const { t } = useTranslation();

  const filters = useDeploymentFilterStore(state => state.filters);
  const [perPage, setPerPage] = useState(ITEMS_PER_PAGE_OPTIONS[0]);

  const scaleQuery = useGetProjectScaleFilterData();
  const dataQuery = useGetRaceToScaleProjects(sectorId, perPage);

  const maxRows = Math.min(MAX_ROWS, dataQuery.data?.dataset.length ? dataQuery.data?.dataset.length : MAX_ROWS);

  const selectOptions = useMemo(() => {
    if (!dataQuery.data?.totalResults) return [];

    const updateOptionsWithTotalResults = (totalResults: number): SelectItem[] => {
      const udpatedOptions: SelectItem[] = ITEMS_PER_PAGE_OPTIONS.filter(option => Number(option) <= totalResults).map(
        option => ({
          label: option,
          value: option,
        })
      );

      return [
        ...udpatedOptions,
        {
          label: t("sectorCompassPage.sections.deployment.raceToScaleAllData"),
          value: totalResults.toString(),
        },
      ];
    };

    return updateOptionsWithTotalResults(dataQuery.data.totalResults);
  }, [dataQuery.data?.totalResults]);

  const { classes, cx } = useStyles({ rows: maxRows, perPageVisible: selectOptions.length > 1 });

  useEffect(() => {
    handlePerPageChange(ITEMS_PER_PAGE_OPTIONS[0]);
  }, [filters]);

  const handlePerPageChange = useCallback((value: string) => {
    setPerPage(value);
  }, []);

  return (
    <RaceToScaleProvider>
      <Box id={DOWNLOAD_IDS.projectsRaceToScaleId} className={classes.container}>
        <Box className={classes.titleRow}>
          <Box className={classes.title}>
            <Text>{t("sectorCompassPage.sections.deployment.raceToScale")}</Text>
            <InfoButton
              autoWidth
              withinPortal
              position="bottom-start"
              contentTitle={t("sectorCompassPage.sections.deployment.operationalInfo.title")}
              content={
                <RtSInfoContent
                  subtitle={t("sectorCompassPage.sections.deployment.raceToScaleInfo.subtitleProjects")}
                />
              }
            />
          </Box>
          <Box className={cx(DOWNLOAD_IGNORE_CLASS, classes.perPageSelect)}>
            <ItemsPerPageSelect data={selectOptions} value={perPage} onChange={handlePerPageChange} />
          </Box>
        </Box>
        <Box className={classes.content}>
          {scaleQuery.isLoading || dataQuery.isLoading ? (
            <Loader />
          ) : scaleQuery.isError || dataQuery.isError ? (
            <ErrorBox text={t("errors.loadingData")} />
          ) : dataQuery.data.dataset.length === 0 ? (
            <NoDataMessageBase />
          ) : (
            <RaceToScaleGrid
              dataScales={scaleQuery.data}
              data={dataQuery.data.dataset}
              rowHeight={ROW_HEIGHT}
              maxRows={maxRows}
            />
          )}
        </Box>
        <Box px="sm" pt="xs" pb="sm">
          <LegendContent payload={dataQuery.data?.config} />
        </Box>
      </Box>
    </RaceToScaleProvider>
  );
};

export default RaceToScale;
