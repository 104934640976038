import { type EntityBE, type EntityBaseBE, type EntityEmojiBE, type FilterUI } from "./base";

type FilterResponse<T> = T[];

export type EntityFilterResponse = FilterResponse<EntityBE>;

export type LocationBE = EntityBaseBE<{
  countries: Nullable<string[]>;
}>;

export type SectorFilterBE = EntityBaseBE<
  EntityEmojiBE & {
    company_count: number;
    insight_count: number;
    is_primary?: boolean;
    children: SectorFilterBE[];
  }
>;
export type SectorFilterResponse = FilterResponse<SectorFilterBE>;

type CheckboxFilterBE = EntityBaseBE<{
  description: Nullable<string>;
  children: CheckboxFilterBE[];
}>;

export type CheckboxFilterResponse = FilterResponse<CheckboxFilterBE>;

export type CheckboxFilterUI = FilterUI & {
  isPrimary?: boolean;
  children?: CheckboxFilterUI[];
};

/** Select Filds ENUMS */
export enum LessThan5Plus500mENUM {
  lessThan5M = "0_4999999",
  _5_19M = "5000000_19999999",
  _20_49M = "20000000_49999999",
  _50_99M = "50000000_99999999",
  _100_499M = "100000000_499999999",
  _500Mplus = "500000000_infinity",
}

export enum OverYearsENUM {
  year = "1_year_over",
  years2 = "2_year_over",
  years5 = "5_year_over",
  years10 = "10_year_over",
}

export enum Past10YearsPlusENUM {
  year = "1_year",
  years2 = "2_year",
  years5 = "5_year",
  years10 = "10_year",
  over10 = "10_year_over",
}

export enum PastYearENUM {
  month = "1",
  months3 = "3",
  months6 = "6",
  months12 = "12",
}

export enum Past3YearsENUM {
  week = "7_day",
  month = "1_month",
  months3 = "3_month",
  months6 = "6_month",
  year = "1_year",
  years3 = "3_year",
  over3 = "3_year_over",
}

export enum InvestmentsRangesENUM {
  _1_10 = "1_10",
  _11_50 = "11_50",
  _51_100 = "51_100",
  _100plus = "101_infinity",
}

/** Bottom options (other, dk, na ...) */
export enum CustomOptionsENUM {
  custom = "custom",
  empty = "",
  all = "All",
}

export enum YesNoENUM {
  yes = "yes",
  no = "no",
}

export type ChipVariant = "checkbox" | "multi" | "single" | "range" | "date-range" | "location" | "autocomplete";
