import { useCallback, FC, ReactNode } from "react";

import { Button, Group, Popover, Text, createStyles, PopoverProps } from "@mantine/core";
import { useDisclosure, useTimeout } from "@mantine/hooks";

import MethodologyIcon from "@Assets/investment-menu-methodology.svg?react";
import { DOWNLOAD_IGNORE_CLASS } from "@Lib/constants/ui";

const DROPDOWN_WIDTH = 250;
const useStyles = createStyles((theme, autoWidth: boolean) => ({
  dropdown: {
    padding: theme.spacing.sm,
    fontWeight: theme.other.fontWeight.semiBold,
    fontSize: theme.fontSizes.sm,
    maxWidth: autoWidth ? "auto" : DROPDOWN_WIDTH,
  },
  header: {
    borderBottom: theme.other.getDefaultBorder(theme),
    paddingBottom: theme.spacing.sm,
  },
  title: {
    color: theme.colors.indigo[5],
    textTransform: "uppercase",
  },
  icon: {
    color: theme.colors.grey[7],
  },
  content: {
    fontWeight: theme.other.fontWeight.regular,
    color: theme.colors.grey[9],
  },
}));

export type InfoButtonProps = Omit<PopoverProps, "opened" | "children"> & {
  content: ReactNode;
  contentTitle?: string;
  autoWidth?: boolean;
};

const InfoButton: FC<InfoButtonProps> = ({ contentTitle, content, autoWidth, ...popoverProps }) => {
  const { classes } = useStyles(autoWidth!);

  const [opened, { close, open }] = useDisclosure(false);
  const { start, clear } = useTimeout(close, 250);

  const handleOpen = useCallback(() => {
    clear();
    open();
  }, []);

  const handleClose = useCallback(() => {
    start();
  }, []);

  return (
    <Popover position="bottom-end" shadow="md" opened={opened} {...popoverProps}>
      <Popover.Target>
        <Button
          variant="subtle"
          c="grey.7"
          compact
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          className={DOWNLOAD_IGNORE_CLASS}
        >
          <MethodologyIcon />
        </Button>
      </Popover.Target>
      <Popover.Dropdown className={classes.dropdown} onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        {contentTitle && (
          <Group className={classes.header} spacing="sm" mb="sm">
            <MethodologyIcon className={classes.icon} />
            <Text className={classes.title}>{contentTitle}</Text>
          </Group>
        )}
        <Text className={classes.content}>{content}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export default InfoButton;
