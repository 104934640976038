import { AnalyticsBrowser } from "@june-so/analytics-next";

const useReverseProxy = import.meta.env.VITE_JUNE_REVERSE_PROXY;
const analytics = AnalyticsBrowser.load({
  writeKey: import.meta.env.VITE_JUNE_WRITE_KEY,
  cdnSettings: useReverseProxy && {
    integrations: {
      "june.so": {
        apiHost: import.meta.env.VITE_API_URL.split("://")[1] + "/june/sdk",
        protocol: import.meta.env.VITE_API_URL.split("://")[0],
      },
    },
  },
});

export default analytics;
