import { useEffect, useCallback } from "react";

import { useListState } from "@mantine/hooks";

type ExpandedIds = string[];
type ToggleExpandFn = (id: string, expanded: boolean) => void;
type ResetExpandFn = () => void;

/**
 * Hook for managing expandable/collapsible state
 * @param initiallyExpanded - Array of IDs that should be expanded initially
 * @returns Tuple containing:
 * - Array of currently expanded IDs
 * - Function to toggle expansion state
 * - Function to reset all expansion states
 */
export const useExpandState = (initiallyExpanded: ExpandedIds = []): [ExpandedIds, ToggleExpandFn, ResetExpandFn] => {
  const [state, handlers] = useListState(initiallyExpanded);

  useEffect(() => {
    if (initiallyExpanded.length) {
      handlers.setState(initiallyExpanded);
    }
  }, [initiallyExpanded]);

  const onExpand = useCallback((id: string, expanded: boolean) => {
    if (expanded) {
      handlers.append(id);
      return;
    }
    handlers.setState(current => current.filter(expandedId => expandedId !== id));
  }, []);

  const resetExpand = useCallback(() => {
    handlers.setState([]);
  }, []);

  return [state, onExpand, resetExpand];
};
